@import 'src/styles/mixins';

.main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.viewLeads {
  padding: 0 1rem;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  // height: 100%;
  padding-bottom: 4rem;
}

.headerBottom {
  // padding: 1rem 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    // width: 45%;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0rem;
    }
  }
}

.deleteAll {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background: var(--c-primary);
  color: var(--c-white);
  font-size: 0.8rem;
  font-weight: 600;
  white-space: nowrap;
  border: 0.1rem solid var(--c-primary);
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.5s ease;

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--c-white);
    color: var(--c-red);
    padding: 0.2rem;
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }

  &:hover {
    transform: scale(1.05);
  }
}

.downloadLeads {
  @include buttonStyle;

  align-self: flex-end;
  margin-right: 1rem;

  &:hover {
    transform: scale(1.05);
  }

  & > span {
    padding-left: 0.5rem;
  }
}

.leadsData {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1279px) {
  .leadsData {
    width: fit-content;
  }

  .headerBottom {
    justify-content: flex-end;

    & > div {
      margin-right: 0.5rem;
    }
  }

  .deleteAll {
    font-size: 0.6rem;
    padding: 0.25rem 0.5rem;

    & > span {
      font-size: 0.6rem;
      padding: 0.1rem;
    }
  }

  .viewLeads {
    padding: 0 0.5rem;
  }

  .downloadLeads {
    width: fit-content;
    padding: 0.25rem 0.5rem;
    margin-left: auto;
    margin-right: 0;
    font-size: 0.6rem;

    & > span {
      padding-left: 0.25rem;
      font-size: 0.8rem;
    }
  }
}
